<template>
  <div class="header-menu" :class="{ 'menu__modal' : isSignedIn, 'menu__side' : !isSignedIn }">
    <i class="icon icon-close menu__side-close" @click="$emit('closeMenu')"/>
    <div class="menu__greeting">
      {{ t('hi') }} {{ userName }}
    </div>
    <div v-if="isSignedIn">
      <a class="menu__side-link" v-if="isAdmin" href="/admin">
        {{ t('admin_dashboard') }}
      </a>
      <a class="menu__side-link" :href="editItineraryUrl" v-if="editItineraryUrl">
        {{ t('edit_itinerary') }}
      </a>
      <a class="menu__side-link" :href="operatorDashboardUrl" v-if="isOperator">
        {{ t('operator_dashboard') }}
      </a>
      <a class="menu__side-link" :href="dashboardUrl">
        {{ t('dashboard') }}
      </a>
      <a class="menu__side-link" :href="exploreUrl">
        {{ t('explore') }}
      </a>
      <a class="menu__side-link" :href="editProfileUrl">
        {{ t('edit_profile') }}
      </a>
      <a class="menu__side-link" :href="bookingUrl">
        {{ t('your_trips') }}
      </a>
    </div>
    <div v-else>
      <a class="menu__side-link" :href="signinUrl">
        {{ t('sign_in_up') }}
      </a>
      <a class="menu__side-link" :href="exploreUrl">
        {{ t('explore') }}
      </a>
    </div>
    <Contact/>
    <div
        v-if="isSignedIn"
        class="menu__logout"
        @click="$emit('logout')"
    >
      <div class="menu__logout-title">
        {{ t('logout') }}
      </div>
      <i class="icon icon-logout menu__logout-icon"/>
    </div>
  </div>
</template>

<script setup>
import Contact from '@/modules/Header/Contact.vue';
import { computed } from "vue";
import { useShared } from "@/helpers/vueHelpers.js";

const signinUrl = document.querySelector("meta[name=menuSigninUrl][content]").content ?? '';
const exploreUrl = document.querySelector("meta[name=menuExploreUrl][content]").content ?? '';
const editProfileUrl = document.querySelector("meta[name=menuEditProfileUrl][content]").content ?? '';
const bookingUrl = document.querySelector("meta[name=menuBookingUrl][content]").content ?? '';
const dashboardUrl = document.querySelector("meta[name=menuDashboardUrl][content]").content ?? '';
const operatorDashboardUrl = document.querySelector("meta[name=menuOperatorDashboardUrl]")?.content ?? '';

const props = defineProps({
  roles: {
    type: Array,
    required: true
  },
  isSignedIn: {
    type: Boolean,
    required: true
  },
  isAdmin: {
    type: Boolean,
    required: true
  },
  userName: {
    type: String,
    required: true
  }
});
defineEmits(['closeMenu', 'logout']);

const {t} = useShared();

const isOperator = computed(() => props.roles.includes('operator'));

const editItineraryUrl = computed(() => {
  if (!props.isAdmin) return null;

  const itineraryId = document.querySelector("meta[name=itineraryId][content]")?.content;
  if (!itineraryId) return null;

  return `/admin/products/${itineraryId}?context=itinerary`;
});
</script>

<style lang="scss" scoped>
$side-width: 265px;
$close-font: 15px;
$line-height-1: 30px;
$line-height-2: 22px;
$custom-radius: 5px;
$modal-top: 76px;

.menu__greeting {
  margin-bottom: $spacing-9;
  font-size: $text-base;
  line-height: $leading-snug;
}

.menu__side {
  position: fixed;
  height: 100%;
  width: $side-width;
  padding: $spacing-6;
  top: 0;
  right: 0;
  background-color: $white;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 50;

  @media (max-width: $breakpoint-m) {
    padding-top: $spacing-26;
  }
}

.menu__side-close {
  position: absolute;
  width: $spacing-6;
  top: $spacing-6;
  right: $spacing-6;
  font-size: $close-font;
  font-weight: $font-bold;
  line-height: $spacing-6;
  text-align: center;
  @media (min-width: $breakpoint-m) {
    display: none;
  }
}

.header-menu .site-nav__inner-button {
  text-decoration: none;
  color: $white;
  cursor: pointer;
  padding: $spacing-2 $spacing-4;
  width: fit-content;
  font-size: $text-base;
  font-weight: $font-semibold;
  line-height: $line-height-2;
}

.menu__side-link {
  display: block;
  margin-bottom: $spacing-4;
  font-size: $text-xl;
  font-weight: $font-semibold;
  line-height: $line-height-1;
  text-decoration: none;
  transition: 0.3s;
  @media (min-width: $breakpoint-m) {
    font-size: $text-base;
    font-weight: $font-medium;
    line-height: $spacing-6;
  }
}

.menu__side-padding {
  margin-top: $spacing-20;
  @media (min-width: $breakpoint-m) {
    margin-top: 0;
  }
}

.icon-close::before {
  font-weight: $font-bold;
}

.menu__modal {
  position: absolute;
  padding: $spacing-6;
  background: $white;
  border: 1px solid $gray-lighter;
  border-radius: $custom-radius;
  top: $modal-top;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  z-index: 50;

  @media (min-width: $breakpoint-m) {
    right: $spacing-12;
  }

  @media (min-width: $breakpoint-lg) {
    right: $spacing-24;
  }

  @media (min-width: $breakpoint-xl) {
    right: $spacing-40;
  }
  @media (min-width: $breakpoint-xxl) {
    right: calc(50% - 800px);
  }

  @media (max-width: $breakpoint-m) {
    position: fixed;
    height: 100%;
    width: $side-width;
    top: 0;
    right: 0;
    border: none;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6.5rem;
    border-radius: 0;
  }
}

.menu__logout {
  display: flex;
  margin-top: $spacing-12;
  cursor: pointer;
}

.menu__logout-title {
  margin-right: $spacing-3;
  font-size: $text-xl;
  font-weight: $font-semibold;
  line-height: $line-height-1;
  color: $gray;

  @media (min-width: $breakpoint-m) {
    font-size: $text-base;
    font-weight: $font-medium;
    line-height: $spacing-6;
  }
}

.menu__logout-icon {
  margin-top: auto;
  margin-bottom: auto;
}

.icon-logout:before {
  font-size: $text-xl;
  font-weight: $font-semibold;
  line-height: $spacing-5;
  color: $gray;

  @media (min-width: $breakpoint-m) {
    font-size: $text-base;
    font-weight: $font-medium;
    line-height: $spacing-4;
  }
}
</style>

<i18n lang="yaml">
en:
  hi: Hi
  plan_a_trip: Plan a trip
  sign_in_up: Sign in / Sign up
  admin_dashboard: Admin dashboard
  edit_itinerary: Edit itinerary
  dashboard: Dashboard
  explore: Explore
  edit_profile: Edit profile
  your_trips: Your trips
  logout: Logout
  operator_dashboard: Operator dashboard
</i18n>
