<template>
  <div class="contact">
    <a class="contact__link" :href="aboutUrl" target="_blank" ref="noreferrer noopener">
      {{ t('about_us') }}
    </a>
    <a class="contact__link" href="/en/magazine/" target="_blank" ref="noreferrer noopener">
      {{ t('tourhero_magazine') }}
    </a>
    <div class="contact__title">
      {{ t('follow_us') }}
    </div>
    <div class="contact__social">
      <a href="https://www.instagram.com/tourherotravel/" target="_blank" ref="noreferrer noopener">
        <i class="icon icon-social-instagram contact__social-icon" />
      </a>
      <a href="https://www.facebook.com/tourherotravel/" target="_blank" ref="noreferrer noopener">
        <i class="icon icon-social-media-facebook contact__social-icon" />
      </a>
      <a href="https://www.linkedin.com/company/tourherotravel/" target="_blank" ref="noreferrer noopener">
        <i class="icon icon-professional-network-linkedin contact__social-icon" />
      </a>
      <a href="https://twitter.com/tourherotravel/" target="_blank" ref="noreferrer noopener">
        <i class="icon icon-social-media-twitter contact__social-icon" />
      </a>
      <a href="https://www.youtube.com/channel/UCSQ3Vx9MZ1Q7OQDvnNmY_YQ/" target="_blank" ref="noreferrer noopener">
        <i class="icon icon-social-video-youtube-clip contact__social-icon" />
      </a>
    </div>
  </div>
</template>

<script setup>

import { useShared } from "@/helpers/vueHelpers.js";

const aboutUrl = document.querySelector("meta[name=menuAboutUrl][content]").content ?? '';

const {t} = useShared();
</script>

<style lang="scss" scoped>
$line-height-1: 18px;
$line-height-2: 30px;

.contact {
  margin-top: $spacing-8;
}

.contact__link {
  display: block;
  margin-bottom: $spacing-4;
  font-size: $text-xl;
  font-weight: $font-semibold;
  line-height: $line-height-2;
  text-decoration: none;
  transition: 0.3s;

  @media (min-width: $breakpoint-m) {
    font-size: $text-base;
    font-weight: $font-medium;
    line-height: $spacing-6;
  }
}

.contact__title {
  margin-bottom: $spacing-4;
  margin-top: $spacing-8;
  font-size: $text-xs;
  font-weight: $font-bold;
  line-height: $line-height-1;
  text-transform: uppercase;
}

.contact__social {
  display: flex;
}

.contact__social-icon {
  width: $spacing-5;
  height: $spacing-5;
  margin-right: $spacing-6;
  font-size: $text-xl;
  line-height: $spacing-5;
}
</style>

<i18n lang="yaml">
en:
  about_us: About Us
  tourhero_magazine: TourHero Magazine
  follow_us: Follow Us
</i18n>