<template>
  <a :href="rootUrl">
    <img class="site-nav__inner-logo" :src="logo" alt="TourHero Logo">
  </a>
  <div class="site-nav__inner-spacer"/>
  <div class="site-nav__inner-button" v-if="shouldShowPlanTripBtn">
    <a :href="planTripUrl">
      {{ t('plan_a_trip') }}
    </a>
  </div>
  <div class="nav-profile site-nav__inner-signed-in" v-if="userSignedIn">
    <div class="nav-profile__dropdown dropdown-menu">
      <div class="nav-profile__dropdown-button" @click.prevent.exact="toggleMenu()">
        <div class="nav-profile__image">
          <img :src="curUserImage">
        </div>
        <i class="icon" :class="{ 'icon-arrow-up-1' : isMenuOpen, 'icon-arrow-down-1' : !isMenuOpen }"/>
      </div>
    </div>
  </div>
  <div class="site-nav__inner-signed-in-menu" v-else-if="userNotSignedIn">
    <div class="dropdown-menu-button" @click="toggleMenu()">
      <i class="icon icon-navigation-menu-1"/>
    </div>
    <div class="site-nav__inner-signed-in-label site-nav__inner-signed-in-desktop">
      <a :href="signInUrl">
        {{ t('sign_in_up') }}
      </a>
    </div>
  </div>
  <div class="site-nav__inner-signed-in-menu" v-else>
  </div>
  <Menu
      ref="menu"
      v-if="isMenuOpen"
      :isSignedIn="userSignedIn"
      :roles="roles"
      @closeMenu="closeMenu()"
      @logout="logout()"
      :isAdmin="isAdmin"
      :userName="displayUserName"
  />
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import api from '@/utils/api.js';
import Menu from '@/modules/Header/Menu.vue';
import logo from 'app/assets/images/logo.png';
import { eraseCookie, getCookieValue } from '@/utils/cookieUtils.js';
import { pathnameWithoutLangPrefix } from "@/utils/stringUtils.js";
import { onClickOutside } from '@vueuse/core'
import { useShared } from "@/helpers/vueHelpers.js";
import { useAuthUserState } from "@/stores/authUser.js";

const rootUrl = document.querySelector("meta[name=menuRootUrl][content]").content ?? '';
const signInUrl = document.querySelector("meta[name=menuSigninUrl][content]").content ?? '';
const logoutUrl = document.querySelector("meta[name=menuLogoutUrl][content]").content ?? '';

const {t, locale} = useShared();
const menu = ref(null);
const isMenuOpen = ref(false);
const isAdmin = ref(false);
const roles = ref([]);
const actualUser = ref('');
const user = ref(null);
const ajaxChecked = ref(false); // tracks whether ajax check for login status is complete

const userSignedIn = computed(() => !!user.value);
const userNotSignedIn = computed(() => (!user.value) && ajaxChecked.value);
const curUserName = computed(() => user.value?.name?.split(' ')[0] || '');
const displayUserName = computed(() => curUserName.value + (actualUser.value ? `(${actualUser.value})` : ''));
const curUserImage = computed(() => user.value.photoUrl);
const planTripUrl = computed(() => `/${locale.value}/plan-a-trip`);
const shouldShowPlanTripBtn = computed(() => {
  const path = pathnameWithoutLangPrefix(location.pathname);
  return path !== '/' && path !== '/plan-a-trip';
});
const authUserState = useAuthUserState();

onClickOutside(menu, closeMenu);

onMounted(async () => {
  if (getCookieValue('_tourhero_loggedin_cache') === "loggedin") {
    // if cookie present, then we may or may not be logged in, call API to ensure
    try {
      const result = await authUserState.fetch();
      // update header accordingly if we have valid logged in session
      if (result) {
        user.value = result;
        isAdmin.value = user.value.meta.isAdmin;
        roles.value = user.value.meta.roles;
        actualUser.value = user.value.meta.actualUser;
        ajaxChecked.value = true;
      }
    } catch (err) {
      console.log(err);
      eraseCookie('_tourhero_loggedin_cache');
      ajaxChecked.value = true;
    }
  }
  // if cookie is not present, we are guaranteed not to be logged in
  // do nothing - but set ajaxChecked because we are already certain
  ajaxChecked.value = true;
});

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}

function closeMenu() {
  isMenuOpen.value = false;
}

async function logout() {
  try {
    await api.delete(logoutUrl);
  } catch (err) {
    console.error(err);
  } finally {
    window.location.replace(rootUrl);
    isMenuOpen.value = false;
  }
}
</script>

<i18n lang="yaml">
en:
  plan_a_trip: Plan a trip
  sign_in_up: Sign in / Sign up
</i18n>
